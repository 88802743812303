.contentSection {
    width: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(252.44deg, #0E112B 0%, #050716 100%);
}

.contact {
    background-blend-mode: darken;
    display: flex;
    justify-content: center;
    max-width: 86vw;
}


.form {
    display: flex;
    flex-direction: column;
    height: 94vh;
    justify-content: center;
    align-items: flex-start;
    font-family: "Lato Light"; 
    width: 100%;
    max-width: 370px;
    animation: fadeIn 0.25s ease-in
}

.formDiv {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 750px;
}

.Icon {
    width: 50vw;
    display: flex;
    align-items: center;
    max-width: 750px;
}

.Icon img {
    max-height: 74vh;
    max-width: 90%;
    object-fit: contain;
    margin-left: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

form label {
    color: #858D98;
    font-size: 0.8rem;
    margin-bottom: 10px;
}

form input {
    background-color: var(--input-bkg);
    border: none;
    border-radius: 7px;
    color: #858D98;
    padding: 0.8rem 0.0rem 0.8rem 20px;
    width: calc(100% - 20px);
    margin-bottom: 2.5vh;
}

form input:focus-visible {
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
}

form textarea {
    background-color: var(--input-bkg);
    border: none;
    border-radius: 7px;
    color: #858D98;
    padding: 0.8rem 0.0rem 0.8rem 20px;
    font-family: "Lato Light";
    width: calc(100% - 20px);
    margin-bottom: 2.5vh;
    resize: vertical;
    min-height: 1rem;
    max-height: 40vh;
}

form textarea:focus-visible {
    box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    margin-bottom: 2.5vh;
}

.submit {
    width: calc(100%);
    background: rgb(103, 115, 233);
    background: linear-gradient(90.7deg, rgba(92, 225, 230, 0.8) 8.85%, rgba(103, 115, 233, 0.8) 94.68%);
    animation: gradientStart 0.05s linear;
}

.submit:hover {
    background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233, 1) 100%);
    animation: gradientAnimation 0.05s linear;
}

@keyframes gradientStart {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.9) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.85) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.80) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.75) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.7) 100%);
    }
}

@keyframes gradientAnimation {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.70) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.75) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.8) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.85) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.9) 100%);
    }
}


.offscreen {
    opacity: 0;
}

/* Placeholders */
.form input::-webkit-input-placeholder, .form textarea::-webkit-input-placeholder { 
    color: #4F5A64; 
    font-style: italic;
}
.form input:-moz-placeholder, .form textarea::-webkit-input-placeholder { /* Firefox 18- */ 
    color: #4F5A64;
    font-style: italic;
}
.form input::-moz-placeholder, .form textarea::-webkit-input-placeholder {  /* Firefox 19+ */ 
    color: #4F5A64;
    font-style: italic;
}
.form input:-ms-input-placeholder, .form textarea::-webkit-input-placeholder {   
    color: #4F5A64; 
    font-style: italic;
}

/* Handle Autofill Style */
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    filter:none;
    transition: background-color 600000s 0s, color 600000s 0s;
}

::-ms-reveal {
    filter: invert(100%);
}

/* On Error */

.labelError {
    color: var(--input-error-bkg);
}

.inputError {
    border: 1px solid var(--input-error-bkg);
    background-color: var(--input-bkg);
    color: var(--input-error-bkg);
}

.submitError, .submitError:hover {
    background: linear-gradient(90.7deg, #40444C 8.85%, #444852 94.68%);
    color: #6C6C6C;
    animation: none;
    width: 370px;
}

.errMsg {
    font-size: 0.7rem;
    margin-top: 20px;
    width: 100%;
    padding-left: 20px;
    display: flex;
    justify-content: center;
}

.errMsgString {
    color: var(--input-error-bkg);
}

/* Mobile */
@media screen and (max-width:800px) {
    .Icon {
        display: none;
    }
}

@media screen and (max-height:700px) {
    form, .formDiv {
        height: auto;
        max-height: unset;
        min-height: 100vh;
    }

    .form {
        height: max-content;
    }
}
 
@media screen and (max-height:600px) {
    .form {
        margin-top: calc(80px - 8vh);
    }
}

@media screen and (max-height:540px) {
    .form {
        margin-top: calc(80px - 4vh);
    }
}

@media screen and (max-width:430px) {
    .formDiv, .form {
        min-width: 75vw;
        max-width: unset;

    }
}

@media screen and (max-width:320px) {
    .formDiv, .form {
        min-width: 86vw;
        max-width: unset;

    }
}