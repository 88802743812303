.mainDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.hidden {
    display: none;
}

.title {
    width: 100%;
    font-family: "Lato Thin";
    padding: 4vh 0;
    margin-bottom: 0;
}

.title span {
    font-family: "Lato";
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width:800px) {
    .content {
        flex-direction: column;
        gap: 5vh;
    }
}