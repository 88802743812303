.swiperHome .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    max-width: none;
    max-height: none;
    overflow: hidden;
  }

.swiperHome .swiper-slide img {
    min-width: 100vw;
    min-height: 100vh;
    object-fit: cover;
    padding-top: 0;
    margin: 0;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    padding-bottom: 15vh;
}

.swiper-button-next {
    margin-right: 10px;
}

.swiper-button-prev {
    margin-left: 10px;
}

/* Mobile */
@media screen and (max-width:1050px) {
    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 30px;
    }
}
