.serviceWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10vh;
}

.serviceColumn img {
    pointer-events: auto;
}

.landingContent {
    z-index: 1;
    position: absolute;
}

.landingFirst {
    margin-top: 16vh;
    width: 84%;
    display: flex;
    flex-direction: column;
    margin-left: -2vw;
}

.landingFirst a:hover {
    cursor: pointer;
}

.landingFirst h1 {
    font-family: "Lato Thin", serif;
    text-align: left;
    padding-top: 3rem;
    margin-bottom: 1rem;
    font-size: 2.7rem;
}

.landingFirst h1 span {
    font-family: "Lato Bold", serif;
}

.landingFirst a {
    text-decoration: none;
    color: white;
}

.landingFirst h3 {
    text-align: left;
    font-size: 0.8rem;
}

.landingFirst a h3 img {
    color: white;
    height: 1.2rem;
    margin-bottom: -0.36rem;
}

.landingFirst a:hover h3 img  {
    animation: arrowLink 1s linear infinite;
}

@keyframes arrowLink {
    from {
        margin-left: -0.2rem;
    }
    to {
        margin-left: 1rem;
    }
}

.landingSecond {
    margin-right: 16vw;
    margin-top: 65vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.landingSecond h1 {
    background: linear-gradient(90.7deg, rgba(92, 225, 230, 0.8) 8.85%, rgba(103, 115, 233, 0.8) 94.68%);
    color: black;
    padding-top: 1.5vh;
    padding-bottom: 0;
    padding-left: 3vw;
    margin-right: 3vw;
    font-size: 2.7rem;
}

.landingSecond h3 {
    font-size: 2.7rem;
    font-family: "Lato Thin", serif;
    margin-right: 0;
}

.landingSecond h3 span {
    font-family: "Lato Light", serif;
}

.serviceColumn {
    box-sizing: border-box;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 80%;
    width: 100%;
}

.serviceColumn a {
    text-decoration: none;
}

.serviceColumn h3 {
    margin: 0;
    color: white;
}

.serviceColumn img {
    height: 200px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.platformDiv {
    background: var(--secGradient);
}

.responsiveTitle {
    display: flex;
}

.responsiveTitle h1 {
    max-width: 100%;
    font-size: 5vw;
    margin-bottom: 20px;
}

.responsiveTitle h3 {
    font-size: 1rem;
    min-width: 430px;
    max-width: 32vw;
    line-height: 1.6rem;
    margin-bottom: 45px;
}

.platformIcon {
    height: 100%;
    display: flex;
    align-items: center;
}

.platformIcon #platIcon {
    object-fit: contain;
    margin: 0;
    max-height: 80%;
}

.platformDivContainer {
    padding-bottom: 8vh;
}

.innerContent h1, .innerContent h3 {
    width: 420px;
}

.innerContent h1 {
    text-align: left;
}

.innerContent h1 span {
    font-family: "Lato Bold", serif;
}

.innerContent h3 {
    text-align: justify;
}

.buttons {
    display: flex;
}

.buttons button {
    cursor: pointer;
    animation: none;
}

.buttons .platformLink {
    margin-right: 1rem;
    background: transparent;
    border: 1px solid white;
    padding: 1.1rem;
    border-radius: 0.5rem;
    font-weight: bold;
    width: 200px;
}

.buttons .platformLink:hover {
    animation: none;
    color: var(--logo);
    border: 1px solid var(--logo);
    transition: all 0.25s ease-in;
}

.buttons .accessLink {
    background: rgb(103, 115, 233);
    width: 200px;
    background: linear-gradient(90.7deg, rgba(92, 225, 230, 0.8) 8.85%, rgba(103, 115, 233, 0.8) 94.68%);
    border: none;
    color: white;
    padding: 1.1rem;
    border-radius: 0.5rem;
    font-weight: bold;
    animation: gradientStart 0.05s linear;
}

.buttons .accessLink:hover {
    background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233, 1) 100%);
    animation: gradientAnimation 0.05s linear;
}

.contentDiv {
    padding-bottom: 4vh;
}

.values img {
    width: 100%;
}

@keyframes gradientStart {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.9) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.85) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.80) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.75) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.7) 100%);
    }
}

@keyframes gradientAnimation {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.70) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.75) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.8) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.85) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(103, 115, 233,0.9) 100%);
    }
}

.button:focus-visible {
    outline: none;
}


.serviceGlass {
    background-color: transparent;
    border-radius: 15px;
    min-height: 25vh;
    backdrop-filter: blur(60px);
}

.bkgOnHover {
    background: transparent;
    max-width: 33%;
}

.bkgOnHover:hover .serviceGlass {
    backdrop-filter: blur(40px);
    z-index: 2;
}

.bkgOnHover:hover {
    background: radial-gradient(circle, rgba(92,225,230,0.8) 9%, rgba(103,115,233,1) 15%, var(--primary) 11%);
}

/* Mobile */

@media screen and (min-width:1500px) {

    .platformDivContainer h3 {
        max-width: 10vw;
    }
}

@media screen and (max-width:1300px) {
    .serviceColumn img {
        height: 100px;
    }
}

@media screen and (max-width:1200px) {

    .platformDivContainer h3 {
        min-width: 150px;
    }

    .buttons .platformLink, .buttons .accessLink {
        width: 16vw;
        padding-left: 8px;
        padding-right: 8px;
    }
}


@media screen and (max-width:960px) {
    .landingSecond {
        margin-top: 75vh;
    }

}

@media screen and (max-width:950px) {
    .platformDivContainer h1 {
        font-size: 3rem;
    }

    .platformDivContainer h3 {
        width: 34vw;
    }

    .buttons .platformLink, .buttons .accessLink {
        width: 150px;
        padding-left: 8px;
        padding-right: 8px;
    }
}

@media screen and (max-width:925px) {
    .platformDivContainer {
        max-height: none;
    }

    .platformDivContainer h3 {
        min-width: 100%;
        max-width: 75vh;
        padding-top: 2rem;
    }

    .buttons {
        width: 100%;
        justify-content: space-between;
    }

    .buttons .platformLink, .buttons .accessLink {
        width: 200px;
    }

    .platformIcon {
        width: 100%;
        display:flex;
        align-items: center;
    }

    .platformIcon img {
        width: 100%;
        max-height: 80vh;
        min-width: 50vw;
        object-fit: contain;
        padding-bottom: 0;
    }
}

@media screen and (max-width:820px) {
    .serviceColumn {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media screen and (max-width:710px) {
    .serviceColumn {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

@media screen and (max-width:750px) {
    .serviceWrapper {
        flex-direction: column;
        overflow: visible;
        align-items: center;
    }

    .bkgOnHover {
        width: 100%;
        max-width: unset;
    }

    .serviceColumn {
        margin-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media screen and (max-width:520px) {
    .responsiveTitle h1 {
        font-size: 6vw;
        width: 100%;
    }

    .innerContent h3 {
        width: 100%;
    }

    .buttons .platformLink, .buttons .accessLink {
        width: 40vw;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width:440px) {
    .landingFirst h1 {
        font-size: 2rem;
    }

    .landingFirst h3 {
        font-size: 0.6rem;
    }

    .landingSecond h1 {
        font-size: 1.8rem;
    }   

    .landingSecond h3 {
        font-size: 1.8rem;
    }
}

@media screen and (max-width:425px) {
    .buttons {
        flex-direction: column;
    }

    .accessLink, .platformLink {
        min-width: 100%;
    }

    .accessLink {
        margin-top: 30px;
    }
}


@media screen and (max-height:850px) and (max-width:950px) {
    .landingFirst {
        margin-top: 70px;
    }

    .landingFirst h1 {
        padding-top: 0;
    }
}

@media screen and (max-height:620px) {
    .landingFirst h1, .landingSecond h1, .landingSecond h3 {
        font-size: 1.7rem;
    }
}

@media screen and (max-height:500px) {
    .landingFirst h1, .landingSecond h1, .landingSecond h3 {
        font-size: 1.4rem;
    }

    .landingFirst h3 {
        font-size: 0.7rem;
    }
}

@media screen and (max-height:450px) {
    .serviceColumn img {
        max-height: 50vh;
    }

    .landingFirst h1, .landingSecond h1, .landingSecond h3 {
        font-size: 1rem;
    }

    .landingFirst h3 {
        font-size: 0.6rem;
    }

    .landingSecond {
        margin-top: 70vh;
    }

    .landingSecond h1 {
        margin-bottom: 0;
    }
}

@media screen and (max-width:800px) {
    #aboutUsIcon {
        max-height: 70vh;
        max-width: unset;
    }
}

@media screen and (max-width:925px) {
    .responsiveTitle h1 {
        font-size: 7vw;
        text-align: center;
    }

    .responsiveTitle h3 {
        font-size: 1.6vw;
    }
}

@media screen and (max-width:750px) {
    .responsiveTitle h1 {
        font-size: 8vw;
    }

    .responsiveTitle h3 {
        font-size: 2vw;
    }
}

@media screen and (max-width:575px) {
    .responsiveTitle h1 {
        font-size: 9vw;
    }

    .responsiveTitle h3 {
        font-size: 2.6vw;
    }
}

@media screen and (max-width:525px) {
    .responsiveTitle h1 {
        font-size: 10vw;
    }

    .responsiveTitle h3 {
        font-size: 3vw;
    }
}

@media screen and (max-width:425px) {
    .responsiveTitle h1 {
        font-size: 11vw;
    }

    .responsiveTitle h3 {
        font-size: 4vw;
    }
}

@media screen and (max-width:350px) {
    .responsiveTitle h1 {
        font-size: 13vw;
    }
}

@media screen and (max-height:350px) {
    .responsiveTitle h1 {
        font-size: 2rem;
    }
}

@media screen and (min-width:1400px) {
    .responsiveTitle h1 {
        font-size: 4.2vw;
    }
}

@media screen and (min-width:1670px) {
    .responsiveTitle h1 {
        font-size: 4vw;
    }
}

@media screen and (min-width:1750px) {
    .responsiveTitle h1 {
        font-size: 4.25rem;
    }
}


