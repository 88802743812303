.swiperContainer {
    height: auto;
    display: flex;
    align-items: center;
    min-height: 80vh;
}

.swiperContainer .swiper {
    position: inherit !important;
}

.swiperImg {
    margin-top: 10px;
    object-fit: cover;
    height: 100%;
}

@media screen and (max-width:1050px) {
    .swiper .swiperSlide {
        min-height: 80vh;
    }
}

.hide {
    display: none;
}

.contentDiv {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text span {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.icon img {
    max-height: 40vh;
    height: 50vh;
    width: 50%;
}