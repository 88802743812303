.dark {
    background: var(--secGradient);
    padding-bottom: 4vh;
}

.intro {
    max-height: 80vh;
    width: 100%;
    max-width: 1500px;
    min-height: 70vh;
    padding-top: 6vh;
    animation: fadeIn 0.25s ease-in;
}

.icon {max-height: 60vh;}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

.intro .splitDiv {
    min-width: 50%;
}

.splitDiv img {
    width:100%;
    height:100%;
    max-height: 70vh;
    object-fit:contain;
    margin: auto;
}

.alignLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navigation img {
    padding-top: 1vh;
    max-height: 10vh;
    animation: arrowsEfect infinite 1.2s ease-in-out;
}

@keyframes arrowsEfect {
    0% {
        opacity: 0;
        margin-top: 0;
    }
    100% {
        opacity: 100%;
        margin-top: 2vh; 
    }
}

.alignLeft p, .alignLeft h1 {
    text-align: left;
}

.splitDivCentered {
    width: 100%;
    height: 100%;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.splitDivCentered p {
    line-height: 1.6rem;
    margin-bottom: 2rem;
}

.splitDivCentered a {
    font-family: "Lato", serif;
    text-decoration: none;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.link {
    display: flex;
}

.go {
    max-height: 1.5rem;
    transform: rotate(-90deg);
    padding-right: 0.9rem;
}

.splitDivCentered a:hover img {
    animation: goAnimation 1s infinite ease-in-out;
}

@keyframes goAnimation {
    0% {
        opacity: 0;
        margin-left: -0.1rem;
    }
    100%{
        opacity: 100%;
        margin-left: 0.5rem;
    }
}

.splitDivCentered img {
    max-width: 67%;
}

.splitDivCentered a img {
    margin: auto;
}

.verticalDiv {
    display: flex;
    flex-direction: column;
}

.verticalDiv p {
    line-height: 1.6rem;
}

.horizontalDiv {
    display: flex;
    width: 100%;
}

.bottomSpace {
    margin-bottom: 20vh;
}

/* Wide Screen Display */

@media screen and (min-width: 1300px) {

    .alignLeft {
        font-size: 1.5rem;
    }

    .alignLeft h3 {
        font-size: 0.8rem;
    }

    .navigation img {
        padding-top: 4vh;
    }
}

/* Mobile Display */
@media screen and (max-width: 1050px) {
    .intro {
        margin-top: 60px;
    }

    .verticalDiv {
        display: block;
    }
}

@media screen and (max-width:925px) {
    .intro {
        flex-direction: column;
        padding-top: 60px;
    }

    .intro {
        text-align: left;
    }

    .intro img {
        max-width: none;
        height: 45vh;
        padding-bottom: 2rem;
    }

    .navigation img {
        position: relative;
        animation: phoneArrowEffect infinite 1.2s ease-in-out;
        bottom: 80px;
    }
    
    @keyframes phoneArrowEffect {
        0% {
            opacity: 100%;
            margin-top: 2vh; 
        }
        100% {
            opacity: 0;
            margin-top: 0; 
        }
    }

    .intro .splitDiv {
        align-items: center;
    }
}

@media screen and (max-width:800px) {
    .splitDivCentered {
        align-items: center;
    }
    
    .splitDivCentered p {
        text-align: justify;
    }


    .splitDivCentered .link {
        text-align: left;
        min-width: 100%;
    }

    .intro h1, .intro h3 {
        text-align: center;
    }
}

@media screen and (max-width:450px) {
    .intro h1 {
        font-size: 3rem;
    }

    .intro h3 {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:360px) {
    .intro h1 {
        font-size: 2rem;
    }

    .intro h3 {
        font-size: 0.6rem;
    }

    .link {
        font-size: 0.9rem;
    }
}

@media screen and (max-width:353px) {
    .link {
        font-size: 0.9rem;
    }

    @keyframes goAnimation {
        0% {
            opacity: 0;
            margin-left: -0.4rem;
        }
        100%{
            opacity: 100%;
            margin-left: 0.0rem;
        }
    }
}

@media screen and (max-width:323px) {
    .link {
        font-size: 0.75rem;
    }
}

@media screen and (max-height:700px) {
    .intro {
        padding-top: 0;
        justify-items: flex-start;
    }

    .intro h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        padding-bottom: 0;
    }

    .intro .splitDiv img {
        max-height: 60vh;
    }

    .navigation img {
        bottom: 40px;
    }
}

@media screen and (max-height:450px) {
    .intro h1 {
        font-size: 2rem;
        padding-top: 20px;
    }

    .navigation {
        display: none;
    }
}