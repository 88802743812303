

.navSettings {
    width: 100%;
    margin-left: 0;
}

.page {
    width: 86vw;
    margin: auto;
}

.mainDiv {
    width: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50%;
}

.left h1 {
    font-family: "Lato Thin";
    font-size: 5vw;
    margin-bottom: 20px;
}

.left h3 {
    font-family: "Lato Thin";
    font-size: 2vw;
}

.left span {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.right {
    display: flex;
    width: 50%;
}

.right img {
    max-width: 35vw;
    max-height: 70vh;
    object-fit: contain;
}

.content {
    display: flex;
    height: 80vh;
    align-items: center;
}

@media screen and (max-width: 925px) {
    .content{
        flex-direction: column;
        align-items: center;
    }

    .left {
        width: 100%;
    }

    .left h1 {
        width: 100%;
        margin-top: 60px;
        font-size: 8vw;
        text-align: center;
    }

    .left h2 {
        width: 100%;
        font-size: 4vw;
        text-align: center;
    }

    .right img {
        width: auto;
        max-height: 70vh;
        object-fit: contain;
        margin: auto;
    }
}

@media screen and (max-width:650px) {
    .left h1 {
        font-size: 10vw;
    }

    .left h3 {
        font-size: 3.5vw;
    }
}

@media screen and (max-width:460px) {
    .left h1 {
        font-size: 12vw;
    }

    .left h3 {
        font-size: 5vw;
    }
}

@media screen and (max-height:530px) {
    .left h1 {
        font-size: 2rem;
        margin-top: 100px;
        padding-bottom: 0;
        margin-bottom: 5px;
    }

    .left h3 {
        font-size: 0.8rem;
        margin-bottom: 2.5px;
    }
}

@media screen and (max-height:400px) {
    .right img {
        max-height: 40vh;
    }
}