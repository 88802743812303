.main {
    width: 100%;
    display: flex;
    justify-content: center;
}

.inner {
    width: 86%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1500px;
}