@import "./css/colors.css";

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: var(--primary);
  min-height: 100vh;
  width: 100%;
  color: white;
  font-family: "Lato Light", serif;
  overflow-x: hidden;
}

.App p {
  line-height: 1.6rem;
}

.App h3 {
  font-size: 0.8rem;
  font-family: "Lato Thin";
  line-height: 1.3rem;
}

.App h1 {
  font-family: "Lato Thin";
}

.footerMain {
  background-color: var(--primary);
}

header {
  width: 100%;
}

img {
  pointer-events: none;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}