.logoSpan {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.introTitle h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.introTitle h3 {
    font-size: 0.8rem;
}

.Text {
    width: 100%;
    text-align: justify;
}

.Cards {
    margin-top: 6vh;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6vh;
}

.card {
    max-width: 25%;
    min-height: 100%;
}

.cardContent {
    width: 100%;
}

.listItems {
    max-width: 100%;
}

.listItem {
    display: flex;
    margin-bottom: 8vh;
    max-width: 100%;
    justify-content: space-between;
}

.iconBox {
    max-width: 20%;
    width: 20%;
}

.titleBox {
    max-width: 80%;
    width: 80%;
}

.listIcon {
    display: flex;
    max-width: 20%;
    width: 20%;
    align-items: center;
}

.listIcon h3 {
    max-width: 80%;
    padding-left: 2rem;
    color: white;
    width: 100%;
    text-align: left;
    font-size: 1rem;
}

.listIcon img {
    margin: auto;
    margin-top: 2px;
    height: 7vh;
    object-fit: contain;
    min-height: 45px;
    min-width: 45px;
}

.listText {
    display: flex;
    flex-direction: column;
    min-width: 70%;
    width: 70%;
    text-align: justify;
}

.listText p {
    line-height: 1.6rem;
}

.listItem {
    gap: 12.5%
}

.listIcon {
    width: 25%;
    min-width: 25%
}

.listText {
    max-width: 62.5%;
    min-width: unset;
}

@media screen and (max-width:1050px) {
    .introTitle h1 {
        font-size: 3rem;
    }
    
    .introTitle h3 {
        font-size: 0.6rem;
    }
    
    .Cards {
        flex-direction: column;
    }

    .card {
        display: flex;
        min-width: 100%;
        align-items: center;
    }

    .listItems {
        flex-direction: column;
        align-items: center;
    }

    .listItem {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        align-items: center;
    }

    .listIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100%;
    }

    .listIcon h3 {
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }

    .listText {
        padding-left: 0;
        width: 100%;
    }

    .listText p {
        width: 100%;
        text-align: justify;
    }

    .listItem {
        gap: auto;
    }
    
    .listIcon {
        width: 100%;
        min-width: 100%;
    }
    
    .listText {
        min-width: 100%;
    }

}

@media screen and (max-width: 925px) {
    .introTitle h1 {
        font-size: 8vw;
    }
    
    .introTitle h3 {
        font-size: 2vw;
    }
}

@media screen and (max-width:600px) {

}

@media screen and (max-width:375px) {
    .introTitle h1 {
        font-size: 10vw;
    }
    
    .introTitle h3 {
        font-size: 4vw;
    }
}

@media screen and (max-height:350px) {
    .introTitle h1 {
        font-size: 1.5rem;
        margin-bottom: 5px;
    }
    
    .introTitle h3 {
        font-size: 0.75rem;
        margin-bottom: 5px;
    }
}