.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 0;
  }
  
.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 35vw;
    height: 35vw;
    max-width: 70vh;
    max-height: 70vh;
  }
  
.swiper-slide img {
    display: block;
    width: 100%;
  }

.swiper-pagination-bullet {
    background-color: white;
  }

.swiper-pagination-bullet-active {
    background-color: white;
  }