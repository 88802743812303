.Card {
    border-radius: 8px;
}

.Icon {
    width: 100%;
    display: flex;
    height: 20vh;
}

.Icon img {
    margin: auto;
    max-width: 10vw;
    height: 10vh;
    min-height: 50px;
    object-fit: contain;
}

.Title {
    width: 100%;
    font-family: "Lato";
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 6vh;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 90%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.Title img {
    display: none;
}

.Content {
    display: flex;
    width: 80%;
    margin: auto;
    text-align: justify;
    margin-bottom: 6vh;
    line-height: 1.6rem;
}

@media screen and (max-width:1050px) {
    .Card {
        display: flex;
        margin-bottom: 10px;
    }

    .contentWrapper {
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-direction: column;
        align-items: center;
    }

    .Title {
        margin: 0;
        padding-bottom: 20px;
        width: 80%;
        margin-left: 0;
    }

    .Content {
        margin-bottom: 0;
    }

    .Icon {
        max-width: 20vw;
        min-width: 20vw;
    }
}

@media screen and (max-width: 600px) {
    
    .Icon {
        display: none;
    }

    .Title {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding-bottom: 6vh;
    }

    .Title img {
        display: inline;
        position: relative;
        height: 45px;
        min-height: 45px;
        padding-bottom: 4vh;
    }
}

@media screen and (max-width:284px) {
    .Title {
        font-size: 0.8rem;
    }
}