:root {
    --primary: #050716;
    --secondary: #1C1E24;
    --secGradient: linear-gradient(106.49deg, #141519 -0.07%, #050716 -0.06%, #0E112B 101.67%);
    --logo: #5CE1E6;
    --footer: #141519;

    /*  FORM COLORS  */
    --input-bkg: #02041B;
    --input-error-bkg: #F0655F;
}