.Header {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 6vh;
    padding-bottom: 6vh;
}

.Content li {
    text-align: left;
}

.Header h1, .Header h3 {
    text-align: left;
}

.Header h1 span {
    color: var(--logo);
    font-family: "Lato Light";
}

.Header h3 {
    padding-top: 2vh;
}

.Articulo {
    font-family: "Lato Bold";
    text-align: left;
}

.Bajada {
    text-align: justify;
}

@media screen and (max-width:1050px) {
    .Header {
        margin-top: 60px;
    }
}

@media screen and (max-width:920px) {
    .Header h1{
        font-size: 3rem;
    }
}

@media screen and (max-width:750px) {
    .Header h1{
        font-size: 2rem;
    }
}

@media screen and (max-width:500px) {
    .Header h1{
        font-size: 1.5rem;
    }
}