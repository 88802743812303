.logoSpan {
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Lato";
}

.introTitle h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.introTitle h3 {
    font-size: 0.8rem;
}

.contentDiv {
    height: auto;
}

.Content {
    padding-top: 0;
    height: max-content;
}

.subTitle {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 1.5rem;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.subTitle p {
    text-align: left;
    margin: auto;
    font-size: 2rem;
    font-family: "Lato Thin", serif;
    line-height: 2.5rem;
}

.subTitle span {
    font-family: "Lato Bold", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.subTitleContent {
    display: flex;
    align-items: center;
}

.listItems :last-child {
    margin-bottom: 0;
}

.listItem {
    display: flex;
    margin-bottom: 4vh;
}

.listIcon {
    display: flex;
    width: 15%;
}

.listIcon img {
    margin: auto;
    margin-top: 2px;
    height: auto;
    object-fit: contain;
    width: 100%;
    min-height:45px;
    min-width: 45px;
}

.listText {
    display: flex;
    flex-direction: column;
    width: 85%;
}

.listText h3 {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 40%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width: 1050px) {
    .introTitle h1 {
        font-size: 3rem;
    }
    
    .introTitle h3 {
        font-size: 0.6rem;
    }
    
    .listIcon {
        width: 20%;
    }
    .listText {
        width: 80%;
    }
}

@media screen and (max-width: 520px) {
    .listIcon {
        width: 25%;
    }
    .listText {
        width: 75%;
    }
}

@media screen and (max-width: 400px) {
    .listItem {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .listText h3 {
        text-align: center;
        margin-top: 8px;
    }
}

@media screen and (max-width:800px) {
    .Content {
        padding-top: 45px;
    }

    .imgDiv {
        display: flex;
        flex-direction: column-reverse;
    }

    .imgDiv img {
        max-height: 50vh;
        margin-bottom: 60px;
    }
}

@media screen and (max-width:400px) {
    .subTitle p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width:290px) {
    .subTitle p {
        font-size: 1rem;
    }
}

@media screen and (max-height:500px) {
    .imgDiv {
        height: auto;
    }

    .imgDiv img {
        max-height: 75vh;
        margin: 0;
    }
}

@media screen and (max-width:925px) {
    .introTitle h1 {
        font-size: 8vw;
    }

    .introTitle h3 {
        font-size: 2.5vw;
    }
}

@media screen and (max-width:350px) {
    .introTitle h1 {
        font-size: 8vw;
    }

    .introTitle h3 {
        font-size: 2.8vw;
    }
}

@media screen and (max-height:350px) {
    .introTitle h1 {
        font-size: 2rem;
        margin-bottom: 2px;
    }
    
    .introTitle h3 {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
}