.page {
    width: 86vw;
    margin: auto;
    max-width: 1500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    min-height: 100vh;
}

.pageContent {
    min-height: 75vh;
}

.logoSpan {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(106, 250, 140, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.mainDiv {
    display: flex;
}

.splitDiv {
    width: 80%;
}

.splitDiv h3 {
    font-size: 305%;
    text-align: center;
    padding-bottom: 2rem;
}

.mainDiv img {
    max-width: 600px;
    padding-bottom: 3rem;
}

.splitDiv p {
    text-align: justify;
}

.splitDiv a {
    color: white;
    text-decoration: none;
    width: unset; 
    background: linear-gradient(90.79deg, #5CE1E6 70%, rgba(106, 250, 140, 1) 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

@media screen and (max-width:1600px) {
    .splitDiv h3 {
        font-size: 3vw;
    }

    .mainDiv img {
        max-width: 40vw;
        margin-top: 10vh;
    }
}

@media screen and (max-width:800px) {
    .mainDiv img {
        padding-bottom: 0rem;
    }
}

@media screen and (max-height:650px) {
    .mainDiv img {
        margin-top: 12vh;
    }
}

@media screen and (max-height:560px) {
    .mainDiv img {
        margin-top: 15vh;
    }
}

@media screen and (max-height:460px) {
    .mainDiv img {
        margin-top: 18vh;
        padding-bottom: 0.5rem;
    }

    .splitDiv h3 {
        padding-bottom: 0.5rem;
    }
}

@media screen and (max-height:325px) {
    .mainDiv img {
        margin-top: 22vh;
    }
}