.footer {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    border-top: 1px solid #282b3f;
}

.footer p {
    line-height: 1.2rem;
}

.columns {
    padding-top: 4rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footerColumn {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.footerColumn h3 {
    color: var(--logo);
    font-size: 0.7rem;
}

.footerColumn a:hover {
    color: var(--logo);
}

.copyright {
    align-items: center;
    font-size: 0.6rem;
}

.copyright img {
    width: 100%;
    padding-bottom: 2rem;
}

.navigation {
    text-align: left;
}

.navigation a {
    color: white;
    text-decoration: none;
    font-size: 0.7rem;
    padding-top: 1.5rem;
}

.navigation a:hover {
    color: var(--logo);
    transition: all 0.25s ease-in;
}

.navigation p {
    font-size: 0.7rem;
    padding-top: 1.5rem;
}

.navigation button {
    color: white;
    border-radius: 100px;
    background-color: var(--primary);
    border: 1px solid white;
    padding: 15px;
    font-size: 0.7rem;
}

.navigation button:hover {
    background: transparent;
    animation: none;
    cursor:pointer;
    color: var(--logo);
    border: 1px solid var(--logo);
    transition: all 0.25s linear;
}

.contacts {
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    padding-bottom: 2rem;
    align-content: center;
}

.contactDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.contactDetails h3 {
    font-size: 0.8rem;
    padding-left: 0.7rem;
    margin-bottom: 0;
}

.contactDetails img {
    max-height: 1rem;
}

.contactDetails a {
    padding-top: 0;
}

.contactsLink {
    cursor: pointer;
}

.contactsLink:hover a {
    border-bottom: 1px solid white;
}

.contactsLink a:hover {
    color: white;
}

/* Mobile */

@media screen and (max-width:750px) {
    .columns {
        flex-direction: column;
        overflow: visible;
        align-items: center;
    }

    .footerColumn {
        padding-bottom: 3rem;
        align-items: center;
        min-width: 200px;
    }

    .footerColumn h3, .footerColumn p {
        text-align: center;
    }

    .contacts {
        flex-direction: column;
        overflow: visible;
        align-items: center;
    }

    .contactDetails {
        padding-bottom: 1rem;
    }

    .contacts span {
        display: none;
    }

}

@media (max-width: 896px) and (min-width: 750px) {
    .contactDetails h3 {
        font-size: 0.6rem;;
    }

    .contactDetails img {
        display: none;
    }
 }