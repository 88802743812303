.black {
    background: linear-gradient(106.15deg, #050716 0.96%, #0E112B 102.41%);
}

.decor1 img {
    position: absolute;
    right: 0;
    top: 0;
    width: 30%;
    z-index: 0;
}

.decor2 img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 17%;
    z-index: 0;
}

h1 {
    font-size: 2rem;
    padding-bottom: 1rem;
}

h3 {
    font-size: 3rem;
    line-height: 0.9rem;
}

.bold {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.introTitle h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.introTitle h3 {
    font-size: 0.8rem;
}

.phone img {
    max-width: 100%;
    object-fit: contain;
}

.infoDiv {
    background-color: rgba(34, 37, 44, 0.5);
}

.Info{
    display: flex;
    margin-bottom: 6vh;
}

.Info img {
    width: 90%;
}

.title {
    width: 100%;
}

.title h1 {
    font-size: 1.5rem;
}

.title h1 span {
    font-family: "Lato Bold", serif;
}

.phoneContainer {
    padding-top: 25px;
    align-items: none;
}

.phoneDivContent {
    display: flex;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    margin-left: 12vh;
}

.infoText {
    min-width: 25vw;
    max-width: 25vw;
    max-height: 10vh;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.infoText h1 {
    font-size: 1.5rem;
    text-align: left;
    width: 100%;
    padding-left: 10px;
}

.infoText h1 span {
    font-family: "Lato Bold", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.infoText p {
    text-align: justify;
    padding-right: 20px;
    padding-left: 10px;
    line-height: 1.6rem;
}

.infoGraphicsWrapper {
    width: 75%;
    display: flex;
}

.infoImg {
    width: fit-content;
    z-index: 2;
}

.infoImg img {
    height: 80vh;
    margin-left: 0;
    object-fit: contain;
    margin-left: -60%;
    min-height: 600px;
}

.imgDescription {
    width: 50%;
    margin-left: -20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-height: 70vh;
    min-height: 520px;
}

.infoItems {
    display: flex;
}

.infoItems img {
    object-fit: contain;
    margin-left: -20%;
}

.infoItems p {
    margin-bottom: 0;
    padding-left: 20px;
}

#second {
    margin-left: -10%;
}

#fourth {
    margin-left: -6%;
}

#fifth {
    margin-left: 6%;
}

#last {
    margin-left: -4%;
}

.gates, .telemetries {
    width: 100%;
    display: flex;
}

.resourceDescription, .iconDiv {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconDiv img {
    max-width: 100%;
    max-height: 75vh;
    object-fit: contain;
}

.listItems {
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.listItems :last-child {
    margin-bottom: 0;
}

.listItem {
    display: flex;
    margin-bottom: 4vh;
}

.listIcon {
    display: flex;
    width: 15%;
}

.listIcon img {
    margin: auto;
    margin-top: 2px;
    height: auto;
    object-fit: contain;
    width: 100%;
    max-height: 60%;
    min-height:45px;
    min-width: 45px;
}

.listText {
    display: flex;
    text-align: left;
    flex-direction: column;
    width: 85%;
    padding-left: 1.5rem;
}

.listText h3 {
    width: 100%;
    text-align: left;
    font-size: 1rem;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

@media screen and (max-width:1460px) {
    .phoneDivContent {
        margin-left: 20%;
    }
}

@media screen and (max-width:1280px) {
    .infoImg img {
        height: 60vh;
    }

    .imgDescription {
        width: 50%;
        margin-left: -20%;
        max-height: 52vh;
    }

    .infoItems p {
        padding-left: 15px;
    }

    .phoneDivContent {
        margin-left: 30%;
    }
    
    .infoItems {
        min-width: max-content;
    }

    .infoGraphicsWrapper {
        margin-left: 5%;
    }
}

@media screen and (max-width: 1200px) {
    .infoGraphicsWrapper {
        margin-left: 3%;
    }
}

@media screen and (max-width: 1146px) {
    .introTitle h1 {
        font-size: 3rem;
        margin-bottom: 20px;
    }
    
    .introTitle h3 {
        font-size: 0.6rem;
    }

    .infoGraphicsWrapper {
        margin-left: 0%;
    }
}

@media screen and (max-width: 960px) {
    .phoneDivContent {
        flex-direction: column-reverse;
        max-height: unset;
        margin-left: 0;
        max-width: 100%;
    }

    .infoText {
        position: static;
        max-height: unset;
        min-width: 100%;
        box-sizing: border-box;
    }

    .infoText h1 {
        max-width: 86vw;
        font-size: 3.3vw;
        box-sizing: border-box;
    }

    .infoText h1 {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .infoText p {
        padding: 0;
    }

    .infoGraphicsWrapper {
        width: 100%;
    }
}

@media screen and (max-width:830px) {
    .infoImg {
        margin-left: -5%;
    }
}

@media screen and (max-width:800px) {
    .imgDescription {
        margin-left: -25%;
    }
}

@media screen and (max-width:770px) {
    .infoItems p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:730px) {
    .imgDescription {
        margin-left: -30%;
    }
}

@media screen and (max-width:710px) {
    .infoGraphicsWrapper {
        flex-direction: column;
        align-items: center;
    }

    .phoneDivContent {
        min-width: 100%;
        align-items: center;
    }

    .infoImg {
        margin-left: 120px;
    }

    .imgDescription {
        margin: 0;
        width: auto;
        min-height: unset;
    }

    .imgDescription, .imgDescription img {
        margin: 0;
        padding: 0;
    }

    .imgDescription p {
        font-size: 1rem;
    }

    .infoItems {
        margin-bottom: 20px;
    }

    #second {
        margin-left: 0;
    }
    
    #fourth {
        margin-left: 0;
    }
    
    #fifth {
        margin-left: 0;
    }
    
    #last {
        margin-left: 0;
    }
}

@media screen and (max-width:345px) {
    .infoImg {
        margin-left: 70px;
    }

    .infoItems p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:710px) and (max-height:600px) {
    .infoImg, .infoImg img {
        height: 75vh;
        min-height: unset;
    }
}

@media screen and (max-width:710px) and (max-height:400px) {
    .infoGraphicsWrapper {
        flex-direction: row-reverse;
    }

    .infoImg img {
        transform: rotate(35deg);
    }

    .infoImg {
        height: fit-content;
    }
}

@media screen and (max-width: 1050px) {
    .listIcon {
        min-height: 100%;
    }

    .listIcon img {
        object-fit: contain;
        margin-top: 0;
    }

    .listText {
        text-align: justify;
    }
}

@media screen and (max-width:925px) {
    .introTitle h1 {
        font-size: 6vw;
    }
    
    .introTitle h3 {
        font-size: 2.5vw;
    }
}

@media screen and (max-width:800px) {
    .listText {
        padding: 0;
    }

    .lists {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: auto;
    }

    .gates {
        flex-direction: column;
        max-width: 50%;
    }

    .telemetries {
        flex-direction: column-reverse;
        max-width: 50%;
    }

    .iconDiv {
        width: 100%;
        height: 40vh;
        justify-content: flex-start;
        margin-bottom: 2rem;
        align-items: center;
    }

    .iconDiv img {
        width: 90%;
        height: 40vh;
    }

    .listItems {
        min-width: 100%;
    }

    .listItem {
        flex-direction: column;
        justify-content: center;
        min-width: unset;
        height: 40vh;
        justify-content: flex-start;
    }

    .listIcon {
        margin: auto;
        min-height: unset;
        height: 25%;
    }

    .listIcon img {
        height: 25%;
    }

    .listText {
        width: 100%;
        height: 75%;
    }

    .listText h3 {
        margin-top: 1rem;
        text-align: center;
    }

    .telemetries .listText p {
        padding-left: 1rem;
    }

    .gates .listText p {
        padding-right: 1rem;
    }
}

@media screen and (max-width:640px) {
    .lists {
        flex-direction: column;
    }

    .gates, .telemetries, .listItems, .listItem {
        min-width: 100%;
    }

    .iconDiv {
        margin-bottom: 0;
    }

    .listItem {
        flex-direction: row;
        height: max-content;
        margin-top: 40px;
    }

    .listIcon {
        height: 30%;
        margin-top: 0;
        min-height: 45px;
        min-width: 45px;
        max-height: 20%;
        width: auto;
        max-width: 20%;
    }

    .listIcon img {
        max-width: 80px;
        margin-top: 15px;
    }
    
    .listText {
        padding-left: 1.5rem;
    }

    .listText h3, .listText p {
        text-align: left;
    }

    .gates .listText p, .telemetries .listText p {
        padding: 0;
        text-align: justify;
    }

    .telemetries:last-child {
        margin-bottom: 60px;
    }
}

@media screen and (max-width:485px) {
    .introTitle h1 {
        font-size: 6.75vw;
    }
    
    .introTitle h3 {
        font-size: 2.75vw;
    }
}

@media screen and (max-width: 450px) {
    .infoText h1 {
        font-size: 1rem;
        text-align: left;
    }
}

@media screen and (max-width: 420px) {
    .listIcon img {
        max-width: unset;
        margin-top: 0;
    }

    .listItem {
        flex-direction: column;
    }

    .listIcon {
        height: min-content;
        width: auto;
        max-height: 60px;
    }

    .listIcon img {
        height: 60px;
        min-height: unset;
        max-height: unset;
    }

    .listText {
        padding: 0;
    }

    .listText h3, .listText p {
        text-align: center;
    }
} 

@media screen and (max-height:420px){
    .listItem {
        height: 52vh;
    }
}

@media screen and (max-height:380px) {
    .listItem {
        height: 55vh;
    }
}

@media screen and (max-width:350px) {
    .introTitle h1 {
        font-size: 10vw;
    }

    .introTitle h3 {
        font-size: 4vw;
    }
}

@media screen and (max-height:320px) {
    .listItem {
        height: 60vh;
    }
}

@media screen and (max-height:300px) {
    .listItem {
        height: 72vh;
    }
}

@media (max-height:420px) and (max-width:640px) {
    .listItem {
        height: max-content;
    }
}

@media screen and (max-height:485px) {
    .introTitle h1 {
        font-size: 2rem;
        margin-bottom: 2px;
    }
    
    .introTitle h3 {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
}


@media screen and (max-height:350px) {
    .introTitle h1 {
        font-size: 1.1rem;
        margin-bottom: 2px;
    }
    
    .introTitle h3 {
        font-size: 0.6rem;
        margin-bottom: 0;
    }
}