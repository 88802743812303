.contentContainer {
    margin-top: 25px;
    animation: fadeIn 0.15s ease-in;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1500px;
}

.mainDiv {
    width: 100%;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}

.FAQs {
    max-height: 80vh;
    width: 40vw;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pageTitle {
    font-size: 3rem;
    text-align: left;
}

.pageTitle span {
    background: linear-gradient(90.79deg, #5CE1E6 30%, rgba(103, 115, 233, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: "Lato", serif;
}

.pageTitle

.Icon {
    height: 65vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40vw;
}

.Icon img {
    max-width: 750px;
    max-height: 65vh;
    margin-right: 0;
    width: 40vw;
    height: 65vh;
}

.element {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.question {
    width: 98%;
    padding-top: 2vh;
    padding-bottom: 2vh;
    background: linear-gradient(91.45deg, rgba(92, 225, 230, 0.1) 0%, rgba(103, 115, 233, 0.1) 96.57%);
    border-radius: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.question:hover {
    cursor: pointer;
}

.question h3 {
    width: calc(100% - 4.5rem);
    font-size: 1rem;
    text-align: left;
    padding-left: 1.5rem;
    margin-bottom: 0;
}

.question img {
    width: 1.5rem;
}

.answer {
    display: none;
}

.show {
    width: calc(100% - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;
    animation: expandDiv 0.5s ease-in;
    margin-bottom: 2vh;
    margin-top: 2vh;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@keyframes expandDiv {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.arrowShow {
    animation: arrowShow 0.5s ease-in;
    transform: rotate(180deg);
}

.arrowHide {
    transform: rotate(0deg);
    animation: arrowHide 0.5s ease-in;
}

@keyframes arrowShow {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
 }

@keyframes arrowHide {
    from {
        transform: rotate(180deg);
    }
to {
        transform: rotate(0deg);
    }
}

@media screen and (max-width:1050px) {
    .contentContainer {
        display: flex;
        flex-direction: column;
    }

    .Icon {
        display: none;
    }

    .FAQs {
        min-width: 100%;
    }
}

@media screen and (max-width:1050px) {
    .FAQs {
        margin-top: 60px;
    }
}