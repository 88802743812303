.navWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav {
    max-width: 1500px;
    z-index: 5;
    width: 86vw;
    height: 60px;
    background-color: transparent;
    overflow-x: hidden;
    display: flex;
}

.logoContainer {
    max-width: 100px;
    max-height: 60px;
    margin-left: 0;
    display: flex;
    align-items: center;
    z-index: 10;
}

.logoContainer img {
    object-fit: contain;
    width: 100px;
    height: 25px;
    margin-top: 14px;
}

.logoContainer a {
    cursor: pointer;
    height: 45px;
    z-index: 2;
}

.bars{display: none;}

.linksWrapper {
    width: 100vw;
    z-index: 5;
}

.linksContainer {
    width: auto;
    height: 60px;
    margin-left: -100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoRep {
    width: 100px;
}

.linksContainer a {
    color: white;
    text-decoration: none;
    font-size: 0.7rem;
}

.linksContainer a:hover, .linksContainer p:hover {
    color: var(--logo);
    cursor: pointer;
    transition: all 0.25s ease-in;
}

.linksContainer button {
    font-family: "Lato Light", serif;
    font-size: 16px;
    background: transparent;
    color: white;
    border: none;
    padding: 0;
    animation: none;
}

.linksContainer button:hover {
    animation: none;
    background: transparent;
}

.linksContainer button img {
    height: 6px;
    margin-left: 8px;
    margin-bottom: 2px;
    z-index: 0;
}

.down img {
    animation: turnDwn 0.1s ease-in;
    transform: rotate(0deg);
}

@keyframes turnDwn {
    from {
        transform: rotate(-180deg);
    } to {
        transform: rotate(0deg);
    }
}

.up img {
    animation: turnUp 0.1s ease-in;
    transform: rotate(-180deg);
}

@keyframes turnUp {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(-180deg);
    }
}

.dropDwnContainer button p {
    margin-bottom: 0;
    max-height: 19px;
    font-size: 0.7rem;
}

.hide {display: none;}

.dropDwnContent {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 175px;
    margin-left: -14.1px;
    height: 165px;
    animation: openDropDwn 0.1s ease-in;
    overflow: hidden;
    -webkit-backdrop-filter: blur(6px) contrast(100%);
    backdrop-filter: blur(6px) contrast(100%);
}

.dropDwnContent .triangle {
    position: absolute;
    margin: auto;
    height: 5px;
    border-bottom: 10px solid rgba(255, 255, 255, 0.2);
    border-left: 6px solid rgba(255, 255, 255, 0); 
    border-right: 6px solid rgba(255, 255, 255, 0);
}

.dropDwnContent .dropDwnNav {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 5px;
    margin-top: 15px;
    height: 150px;
    width: 100%;
    background:rgba(255, 255, 255, 0.2);
}

@keyframes openDropDwn {
    from {
        height: 0;
    } to {
        height: 158px;
    }
}

.dropDwnContent .dropDwnNav a {
    text-align: left;
    padding: 0.8rem 0 0.8rem 14px;
    font-size: 0.6rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);;
}

.dropDwnContent .dropDwnNav :last-child {
    border-bottom: none;
}

.dropDwnCollapse {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 0;
    margin-left: -14.1px;
    height: 0;
    animation: closeDropDwn 0.1s ease-in;
    overflow: hidden;
}

.dropDwnCollapse .dropDwnNav {
    display: flex;
    flex-direction: column;
    position: absolute;
    border-radius: 5px;
    margin-top: 15px;
    height: 158px;
    width: 100%;
    background:rgba(255, 255, 255, 0.2);
}

.dropDwnCollapse .triangle {
    position: absolute;
    margin: auto;
    height: 5px;
    border-bottom: 10px solid rgba(255, 255, 255, 0.2);
    border-left: 6px solid rgba(255, 255, 255, 0); 
    border-right: 6px solid rgba(255, 255, 255, 0);
}

.dropDwnCollapse .dropDwnNav a {
    text-align: left;
    padding: 0.8rem 0 0.8rem 14px;
    font-size: 0.6rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);;
}

.dropDwnCollapse .dropDwnNav :last-child {
    border-bottom: none;
}

@keyframes closeDropDwn {
        from {
            height: 158px;
            width: 175px;
        } to {
            height: 0;
            width: 175px;
    }
}

/* Mobile Version */

@media screen and (max-width:1050px) {
    .navWrapper {
        background: rgba(0, 0, 0, 0.8);
        width: 100vw;
        z-index: 50;
        position: fixed;
    }

    .nav {
        flex-direction: column;
        overflow: visible;
        
    }
    
    .logoContainer {
        min-width: 100vw;
        margin-left: -6vw;
        display: flex;
        justify-content: space-between;
        -webkit-backdrop-filter: blur(6px) contrast(100%);
        backdrop-filter: blur(6px) contrast(100%);
    }

    .logoContainer button {
        padding: 0;
        margin-right: 7.5vw;
    }

    .logoContainer img {
        width: 130px;
        height: 45px;
        margin: auto auto auto 6vw;
    }

    .bars {
        display: flex;
        background-color: transparent;
        border: none;
        height: 60px;
        animation: none;
    }

    .bars:hover {
        background: transparent;
        animation: none;
    }

    .bars img {
        height: 30px;
        width: 30px;
    }

    .dropDwnContent {
        width: 100vw;
        height: 158px;
        margin-top: 0;
        margin-left: 0;
        position: relative;
    }

    .logoRep {display: none;}

    .linksWrapper {
        margin-top: -0.6px;
        animation: openMenu 0.5s ease-in;
        min-height: 405px;
        max-height: 563px;
        width: auto ;
    }

    .linksContainer {
        flex-direction: column;
        background: rgba(0, 0, 0, 0.8);
        min-width: 116.5%;
        margin-left: -7vw;
        height: auto;
        overflow: hidden;
        animation: openMenu 0.5s ease-in;
        -webkit-backdrop-filter: blur(6px) contrast(100%);
        backdrop-filter: blur(6px) contrast(100%);
    }

    @keyframes openMenu {
        from {
            height: 0;
        } to {
            height: 405px;
        }
    }

    .linksContainer a {
        font-size: 16px;
        width: calc(100%);
        padding-left: 14vw;
        text-align: left;
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .linksContainer button {
        width: 100%;
        text-align: left;
        position: relative;
        height: 80px;
        box-sizing: border-box;
    }

    .linksContainer button p {
        font-size: 16px;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-top: -3px;
        text-align: left;
        padding-left: 7vw;
    }

    .dropDwnContainer {
        width: 100%;
    }

    .linksContainer p:hover {
        color: white;
    }

    .linksContainer :nth-child(3) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .linksContainer .dropDwnContent .triangle {
        display: none;
    }

    .linksContainer .dropDwnContent .dropDwnNav {
        position: relative;
        background-color: transparent;
        width: 100%;
        margin-top: 0;
    }

    .linksContainer .dropDwnContent {
        animation: openDropDwn 0.5s ease-in;
        height: 150px;
        backdrop-filter: none;
    }

    .linksContainer .dropDwnContainer .dropDwnNav a {
        padding-left: 9vw;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .linksContainer .dropDwnContainer .dropDwnNav :first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    .dropDwnCollapse {
        position: relative;
        margin-left: 0;
        width: 0;
        height: 0;
        animation: closeDropDwnMobile 0.5s ease-in;
        overflow: hidden;
    }
    
    .linksContainer .dropDwnCollapse .dropDwnNav {
        position: relative;
        background-color: transparent;
        width: 0;
        height: 0;
        margin-top: 0;
        animation: closeDropDwnMobile 0.5s ease-in;
    }

    .linksContainer .dropDwnCollapse .dropDwnNav a {
        padding-left: 9vw;
    }
    
    .dropDwnCollapse .triangle {
        display: none;
    }

    @keyframes closeDropDwnMobile {
        from {
            height: 158px;
            width: 100%;
        } to {
            height: 0;
            width: 100%;
        }
    }

    .collapseWrapper {
        margin-top: -0.6px;
        height: 0;
        width: 0;
        z-index: 5;
        animation: closeMenu 0.5s ease-in;
    }

    .collapseWrapper .linksContainer {
        height: 0;
        width: 0;
        animation: closeMenu 0.5s ease-in;
    }

    .up img {
        animation: turnUp 0.5s ease-in;
    }

    .down img {
        animation: turnDwn 0.5s ease-in;
    }
    
    @keyframes closeMenu {
        from {
            height: 405px;
            width: 100%;
        } to {
            height: 0;
            width: 100%;
        }
    }
}

@media screen and (max-height:628px) {
    .linksContainer a, .linksContainer button p {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .linksContainer button {
        height: 51px;
    }

    @keyframes openMenu {
        from {
            height: 0;
        } to {
            height: 265px;
        }
    }

    @keyframes closeMenu {
        from {
            height: 265px;
            width: 100%;
        } to {
            height: 0;
            width: 100%;
        }
    }
}

@media screen and (max-height:475px) {

    .linksWrapper {
        position: relative;
        height: 100%;
    }

    .linksContainer {
        position: relative;
        overflow-y: scroll;
        justify-content: flex-start;
        height: calc(100vh - 58px);
    }

    .navbar_linksContainer__QzC9B .navbar_dropDwnContent__uYK0C .navbar_dropDwnNav__WzXYr {
        height: max-content;
        position: relative
    }

    @keyframes openMenu {
        from {
            height: 0;
        } to {
            height: 100vh;
        }
    }

    @keyframes closeMenu {
        from {
            height: 100vh;
            width: 100%;
        } to {
            height: 0;
            width: 100%;
        }
    }
}