body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: "Lato";
  src: local("Lato", url(./css/Lato/Lato-Regular.ttf), format("truetype"));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Lato";
  src: local("Lato"), url("./css/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato Thin";
  src: local("Lato Thin"), url("./css/Lato/Lato-Thin.ttf") format("truetype")
}

@font-face {
  font-family: "Lato Light";
  src: local("Lato Light"), url("./css/Lato/Lato-Light.ttf") format("truetype")
}

@font-face {
  font-family: "Lato Bold";
  src: local("Lato Bold"), url("./css/Lato/Lato-Bold.ttf") format("truetype")
}

@font-face {
  font-family: "Lato Black";
  src: local("Lato Black"), url("./css/Lato/Lato-Black.ttf") format("truetype")
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin-top: 0;
}