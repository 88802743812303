.button {
    background: rgb(92,225,230);
    background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.7) 100%);
    border: none;
    color: white;
    padding: 1.1rem;
    border-radius: 0.5rem;
    font-weight: bold;
    animation: gradientStart 0.1s linear;
}

button:hover {
    background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.9) 100%);
    animation: gradientAnimation 0.1s linear;
}

@keyframes gradientStart {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.9) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.85) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.80) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.75) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.7) 100%);
    }
}

@keyframes gradientAnimation {
    0% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.70) 100%);
    }
    
    25% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.75) 100%);
    }

    50% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.8) 100%);
    }

    75% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.85) 100%);
    }

    100% {
        background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.9) 100%);
    }
}

.button:focus-visible {
    outline: none;
}