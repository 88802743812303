.introTitle h1 {
    font-size: 4rem;
    margin-bottom: 20px;
}

.introTitle h3 {
    font-size: 0.8rem;
}

.logoSpan {
    font-family: "Lato", serif;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.listItems {
    max-width: 100%;
}

.listItem {
    display: flex;
    margin-bottom: 8vh;
    max-width: 100%;
    justify-content: space-between;
}

.iconBox {
    max-width: 20%;
    width: 20%;
}

.titleBox {
    max-width: 80%;
    width: 80%;
}

.listIcon {
    display: flex;
    max-width: 20%;
    width: 20%;
    align-items: center;
}

.listIcon h3 {
    max-width: 80%;
    padding-left: 2rem;
}

.listIcon img {
    margin: auto;
    margin-top: 2px;
    height: 7vh;
    object-fit: contain;
    min-height: 45px;
    min-width: 45px;
    max-width: 3rem;
}

.listText {
    display: flex;
    flex-direction: column;
    min-width: 70%;
    width: 70%;
    text-align: justify;
}

.firstItems {
    width: 100%;
    text-align: left;
    background: linear-gradient(90.79deg, #5CE1E6 0%, rgba(103, 115, 233, 1) 60%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
}

.lastItem {
    color: white;
    width: 100%;
    text-align: left;
    font-size: 1rem;
}

/* Wide Screen Display */

@media screen and (min-width: 1300px) {
    .page h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width:1180px) {
    .introTitle h1 {
        font-size: 3rem;
    }
    
    .introTitle h3 {
        font-size: 0.6rem;
    }
}

@media screen and (max-width:375px) {
    .introTitle h1 {
        font-size: 10vw;
    }
    
    .introTitle h3 {
        font-size: 4vw;
    }
}

@media screen and (max-width: 880px) {
    .listItems {
        flex-direction: column;
        align-items: center;
    }

    .listItem {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        align-items: center;
    }

    .listIcon {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 100%;
    }

    .listIcon h3 {
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        width: 100%;
        max-width: 100%;
    }

    .listText {
        padding-left: 0;
        width: 100%;
    }

    .listText p {
        width: 100%;
        text-align: justify;
    }
}

@media screen and (max-height:350px) {
    .introTitle h1 {
        font-size: 2rem;
        margin-bottom: 2px;
    }
    
    .introTitle h3 {
        font-size: 0.8rem;
        margin-bottom: 0;
    }
}