.swal2-container.swal2-center>.swal2-popup {
    background: linear-gradient(252.44deg, #0E112B 0%, #050716 50%);
    color: white;
    border-radius: 10px;
    font-size: 0.75rem;
    width: 370px;
    margin-left: 20px;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: rgba(0,0,0, 0.8) !important;
}

.swal2-close:focus {
    box-shadow: none !important;
}

.swal2-popup button:hover {
    animation: none !important;
}

.swal2-timer-progress-bar {
    background: linear-gradient(90deg, rgba(92,225,230,1) 0%, rgba(161,220,146,0.9) 100%) !important;
}